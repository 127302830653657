import { create } from 'zustand';

export const useQuestionStore = create((set) => ({
  todayCountryDate: null,
  isShow: false,
  isFiltered: false,
  initFrontSelected: {
    searchValue: '',
    date: [null, null],
    states: ['Progress', 'Complete'],
  },
  frontSelected: {
    searchValue: '',
    date: [null, null],
    states: ['Progress', 'Complete'],
  },
  selected: {
    questionCategoryPks: [],
    regions: [],
    countries: [],
    attachmentFileExist: [],
    urgent: [],
  },

  setIsShow: (props) => set((state) => ({ ...state, isShow: props })),
  setIsFiltered: (props) => set((state) => ({ ...state, isFiltered: props })),

  setSearchValue: (props) =>
    set((state) => ({
      ...state,
      frontSelected: { ...state.frontSelected, searchValue: props },
    })),
  setDate: (props) =>
    set((state) => ({
      ...state,
      frontSelected: { ...state.frontSelected, date: props },
    })),
  setStates: (props) =>
    set((state) => ({
      ...state,
      frontSelected: { ...state.frontSelected, states: props },
    })),
  select: (props) =>
    set((state) => ({
      ...state,
      selected: props,
    })),

  resetQuestionData: () =>
    set((state) => ({
      ...state,
      isShow: false,
      isFiltered: false,
      frontSelected: {
        searchValue: '',
        date: [null, state.todayCountryDate],
        states: ['Progress', 'Complete'],
      },
      selected: {
        questionCategoryPks: [],
        regions: [],
        countries: [],
        attachmentFileExist: [],
        urgent: [],
      },
    })),
  resetDate: () =>
    set((state) => ({
      ...state,
      frontSelected: {
        ...state.frontSelected,
        date: [null, state.todayCountryDate],
      },
    })),
  resetFrontSelected: () =>
    set((state) => ({
      ...state,
      frontSelected: {
        searchValue: '',
        date: [null, state.todayCountryDate],
        states: ['Progress', 'Complete'],
      },
    })),
  resetSelected: () =>
    set((state) => ({
      ...state,
      selected: {
        questionCategoryPks: [],
        regions: [],
        countries: [],
        attachmentFileExist: [],
        urgent: [],
      },
    })),
}));
