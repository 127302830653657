import React, { lazy } from 'react';
import { Navigate } from 'react-router-dom';

//common
const NotFoundError = lazy(() => import('./pages/error/NotFoundError'));

//Auth View
const Login = lazy(() => import('./views/auth/Login'));
const Join = lazy(() => import('./views/auth/Join'));
const SetPw = lazy(() => import('./views/auth/SetPw'));

//Page View
const MainPage = lazy(() => import('./views/main/MainPage'));
const ProjectList = lazy(() => import('./views/main/Project'));
const SubjectList = lazy(() => import('./views/main/Subject'));
const Dashboard = lazy(() => import('./views/main/Dashboard'));
const QueryList = lazy(() => import('./views/main/QueryPage'));
const QueryDetail = lazy(() => import('./views/main/QueryPage/QueryDetail'));
const NewQuery = lazy(() => import('./views/main/QueryPage/NewQuery'));
const MultiFormPopup = lazy(() => import('./views/main/Dashboard/SideMenu/MultiFormPopup'));
const ImageViewPopup = lazy(() => import('./views/main/Dashboard/SideMenu/ImageViewPopup'));
const DataImportPopup = lazy(() => import('./views/admin/ProjectManagement/Import/DataImportPopup'));
const ImageImportPopup = lazy(() => import('./views/admin/ProjectManagement/Import/ImageImportPopup'));

//Admin Page
const UserManagement = lazy(() => import('./views/admin/UserManagement'));
const Notice = lazy(() => import('./views/admin/Notice'));
const ProjectManagement = lazy(() => import('./views/admin/ProjectManagement'));
const AuthManagement = lazy(() => import('./views/admin/AuthManagement'));
const ExportAuditManagement = lazy(() => import('./views/admin/ExportAuditManagement'));

//Test Page
const UploadPage = lazy(() => import('./views/Import'));

// Layout
const WrapLayout = lazy(() => import('./components/Common/Layout/WrapLayout'));
const AuthLayout = lazy(() => import('./layouts/AuthLayout'));
const MainPageLayout = lazy(() => import('./layouts/MainPageLayout'));
const ProjectLayout = lazy(() => import('./layouts/ProjectLayout'));
const SubjectLayout = lazy(() => import('./layouts/SubjectLayout'));
const DashboardLayout = lazy(() => import('./layouts/DashboardLayout'));
const AdminLayout = lazy(() => import('./layouts/AdminLayout'));
const NewWindowLayout = lazy(() => import('./layouts/NewWindowLayout'));
const QueryPageLayout = lazy(() => import('./layouts/QueryPageLayout'));
const ESignLayout = lazy(() => import('./layouts/ESignLayout'));
const WindowClose = lazy(() => import('./views/WindowClose'));

// Full Window
const QuestionPdfPreview = lazy(() => import('./pages/question/QuestionPdfPreview'));

// Question Page
const QuestionList = lazy(() => import('./pages/question/QuestionList'));
const QuestionDetail = lazy(() => import('./pages/question/QuestionDetail'));
const AddQuestion = lazy(() => import('./pages/question/AddQuestion'));

// ESign Page
const ESign = lazy(() => import('./pages/e-sign/ESign'));
const ESignEdit = lazy(() => import('./pages/e-sign/ESignEdit'));
const ESignListProgress = lazy(() => import('./pages/e-sign/ESignListProgress'));
const ESignListDone = lazy(() => import('./pages/e-sign/ESignListDone'));

// Chart Page
const Treatment = lazy(() => import('./pages/chart/Treatment'));
const LabPatho = lazy(() => import('./pages/chart/LabPatho'));

const routes = [
  {
    path: '/',
    children: [
      { path: '/', element: <Navigate to="/auth/login" /> },
      { path: '404', element: <NotFoundError /> },
      { path: '*', element: <Navigate to="/404" /> },
    ],
  },

  {
    path: '/auth',
    element: <AuthLayout />,
    children: [
      { path: 'login', element: <Login /> },
      { path: 'join', element: <Join /> },
      { path: 'set-pw', element: <SetPw /> },
      // { path: 'test', element: <WebSocketTest /> },
      { path: '*', element: <Navigate to="/404" /> },
    ],
  },
  {
    path: '/main',
    element: <MainPageLayout />,
    children: [
      { path: '', element: <MainPage /> },
      { path: '*', element: <Navigate to="/404" /> },
    ],
  },
  {
    path: '/project',
    element: <ProjectLayout />,
    children: [
      { path: '', element: <ProjectList /> },
      { path: '*', element: <Navigate to="/404" /> },
    ],
  },
  {
    path: '/project/:projectId/*',
    element: <SubjectLayout />,
    children: [
      { path: 'subject', element: <SubjectList /> },
      // {
      //   path: 'e-sign/',
      //   element: <ESignLayout />,
      //   children: [
      //     { path: '', element: <ESign /> },
      //     { path: 'list-progress', element: <ESignListProgress /> },
      //     { path: 'list-done', element: <ESignListDone /> },
      //   ],
      // },
      { path: '*', element: <Navigate to="/404" /> },
    ],
  },

  // full window
  {
    path: '/full-window/*',
    children: [
      {
        path: 'project/:projectPk/question/:questionPk/question-pdf-preview',
        element: <QuestionPdfPreview />,
      },
      { path: '*', element: <Navigate to="/404" /> },
    ],
  },

  // project
  {
    path: '/project/:projectPk/*',
    element: <WrapLayout />,
    children: [
      { path: 'question', element: <QuestionList /> },
      { path: 'question/:questionPk', element: <QuestionDetail /> },
      { path: 'question/add', element: <AddQuestion /> },
      {
        path: 'question/:questionPk/question-pdf-preview',
        element: <QuestionPdfPreview />,
      },
      { path: '*', element: <Navigate to="/404" /> },
    ],
  },
  // old project
  {
    path: '/project/:projectId/*',
    element: <SubjectLayout />,
    children: [
      { path: 'question/list', element: <QueryList /> },
      { path: '*', element: <Navigate to="/404" /> },
    ],
  },
  {
    path: '/question/*',
    element: <QueryPageLayout />,
    children: [
      { path: ':queryId/:state', element: <QueryDetail /> },
      { path: 'new', element: <NewQuery /> },
      { path: '*', element: <Navigate to="/404" /> },
    ],
  },

  {
    path: '/subject/:subjectId/*',
    element: <DashboardLayout />,
    children: [
      { path: 'dashboard', element: <Dashboard /> },
      { path: '*', element: <Navigate to="/404" /> },
    ],
  },
  {
    path: '/dashboard/:subjectId/*',
    element: <NewWindowLayout />,
    children: [
      { path: 'multi-report', element: <MultiFormPopup /> },
      { path: 'image-view', element: <ImageViewPopup /> },
      { path: '*', element: <Navigate to="/404" /> },
    ],
  },

  // {
  //   path: '/e-sign/',
  //   element: <NewWindowLayout backgroundColor='rgb(248, 248, 248)' />,
  //   children: [
  //     { path: 'edit', element: <ESignEdit /> },
  //     { path: '*', element: <Navigate to="/404" /> },
  //   ],
  // },
  // {
  //   path: '/video-conference/',
  //   element: <NewWindowLayout />,
  //   children: [
  //     { path: 'meeting', element: <Meeting /> },
  //     { path: '*', element: <Navigate to="/404" /> },
  //   ],
  // },
  // {
  //   path: '/dashboard/:subjectId/chart/*',
  //   element: <ChartLayout />,
  //   children: [
  //     { path: 'treatment', element: <Treatment /> },
  //     { path: 'lab-patho', element: <LabPatho /> },
  //     { path: '*', element: <Navigate to="/404" /> },
  //   ],
  // },

  {
    path: '/admin/*',
    element: <AdminLayout />,
    children: [
      { path: '', element: <UserManagement /> },
      { path: 'users', element: <UserManagement /> },
      { path: 'notice', element: <Notice /> },
      { path: 'project', element: <ProjectManagement /> },
      { path: 'authority', element: <AuthManagement /> },
      { path: 'data-audit', element: <ExportAuditManagement /> },
      { path: '*', element: <Navigate to="/404" /> },
    ],
  },
  {
    path: '/admin/project/:projectPk/',
    element: <NewWindowLayout />,
    children: [
      { path: ':fileType/data-import', element: <DataImportPopup /> },
      { path: ':fileType/image-import', element: <ImageImportPopup /> },

      // { path: 'SUBJECT_INFO', element: <DataImportPopup /> },
      // { path: 'DISEASE_INFORMATION', element: <DataImportPopup /> },
      // { path: 'MEDICAL_HISTORY', element: <DataImportPopup /> },
      // { path: 'TREATMENT', element: <DataImportPopup /> },
      // { path: 'LAB-PATHO', element: <DataImportPopup /> },
      // { path: 'IMAGE', element: <ImageImportPopup /> },
      // { path: 'RESPONSE', element: <DataImportPopup /> },
      // { path: 'AE_REPORT', element: <DataImportPopup /> },
      // { path: 'REPORT_TL', element: <DataImportPopup /> },
      // { path: 'REPORT_NTL', element: <DataImportPopup /> },
      // { path: 'REPORT_NL', element: <DataImportPopup /> },
      // { path: 'REPORT_OR', element: <DataImportPopup /> },
      { path: '*', element: <Navigate to="/404" /> },
    ],
  },

  {
    path: '/window-close',
    element: <WindowClose />,
  },
  {
    path: '/test',
    element: <UploadPage />,
  },
];

export default routes;
